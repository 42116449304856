import React, { Component } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import SimpleMap from "./mapcomp/simpleMap";
import bodybasic from "./zebspics/bodybasic.jpeg";
import luxsalonlogo from "./zebspics/luxsalonlogo.jpeg";
import sanitarynozeb from "./zebspics/sanitarymassageroomnozeb.jpeg";
import zebsittingwide from "./zebspics/zebsittingwide.jpeg";
import webinfo from "./infoVar";

interface Props {
  children: React.ReactChild;
}

const test = () => {
  const testr = webinfo.carosel_info.photoIdList.map((value) => (
    <div className="h-100" style={{ backgroundColor: "black" }}>
      <img
        // className="img-responsive"
        style={{
          height: "75vh",
          objectFit: "contain",
        }}
        src={value}
      />
    </div>
  ));
  return testr;
};

class DemoCarousel extends Component {
  render() {
    return (
      <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
        <Carousel showThumbs={false}>{test()}</Carousel>
      </div>
    );
  }
}

function Home() {
  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-1 col-md-1"></div>
        <div className="col-sm-12 col-md-5">
          <h1 className="text-mid font-weight-light">General Info</h1>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Contact</th>
                <th scope="col">Info</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Phone:</td>
                <td>{webinfo.contact_info.phone}</td>
              </tr>
              <tr>
                <td>Facebook:</td>
                <td>
                  <p>
                    <a href={webinfo.contact_info.facebook}>Zeb Wickman</a>
                  </p>
                </td>
              </tr>
              <tr>
                <td>Location:</td>
                <td>{webinfo.location_info.address_full}</td>
              </tr>
              <tr>
                <td>Availability:</td>
                <td>{webinfo.location_info.hours}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-sm-12 col-md-5">
          <h1 className="text-mid font-weight-light">Rates</h1>
          <table className="table">
            <thead className="thead-dark">
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Length</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Therapeutic Massage</td>
                <td>
                  60 min <br></br>(or 1$ per min)
                </td>
                <td>60$</td>
              </tr>
              <tr>
                <td>Sports Massage</td>
                <td>30 min</td>
                <td>20$</td>
              </tr>
              <tr>
                <td>Business or Event Massage</td>
                <td>60 min</td>
                <td>100$ per hour</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-sm-1 col-md-1"></div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-1"></div>
        {/* <div className="col-sm-12 col-md-10">
          <DemoCarousel></DemoCarousel>
        </div> */}
        <div className="col-sm-12 col-md-1"></div>
      </div>
      <div className="row">
        <div className="col-sm-0 col-md-1"></div>
        <div className="col-sm-0 col-md-1"></div>
      </div>
    </div>
  );
}

export default Home;
