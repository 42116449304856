import React from "react";
import zebWideShot from "./zebspics/zebsittingwide.jpeg";
import SimpleMap from "./mapcomp/simpleMap";
import LocationRow from "./locationrow";
import webinfo from "./infoVar";

function About() {
  return (
    <div className="about">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              style={{
                height: "75vh",
                objectFit: "contain",
              }}
              className="img-fluid rounded mb-4 mb-lg-0"
              src={webinfo.bodyBasicsPhoto}
              alt=""
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Massage Modalities</h1>
            <ul style={{ listStyle: "none", paddingRight: "30px" }}>
              <li>Chronic Pain Reduction</li>
              <li>Sports and Athletic</li>
              <li>Swedish Deep Tissue</li>
              <li>Myofascial Treatments</li>
              <li>Reflexology</li>
              <li>Neuropathic Massage</li>
              <li>Injury Recovery Treatments</li>
            </ul>
            <h1 className="font-weight-light">Location</h1>
            <p>Address: {webinfo.location_info.address_full}</p>
            <p>{webinfo.location_info.description}</p>
            <p></p>
          </div>
        </div>
        {/* <LocationRow></LocationRow> */}
      </div>
    </div>
  );
}

export default About;
