import LocationRow from "./locationrow";
import bodyBasic from "./zebspics/bodybasic.jpeg";
import webinfo from "./infoVar";
import React from "react";

function Contact() {
  return (
    <div className="contact">
      <div className="container">
        <div className="row align-items-center my-5">
          <div className="col-lg-7">
            <img
              className="img-fluid rounded mb-4 mb-lg-0"
              style={{
                height: "75vh",
                objectFit: "contain",
              }}
              src={webinfo.bodyBasicsPhoto}
              alt=""
            />
          </div>
          <div className="col-lg-5">
            <h1 className="font-weight-light">Massage Therapist Contact</h1>
            <p>Phone: {webinfo.contact_info.phone}</p>
            <p>
              FaceBook: <a href={webinfo.contact_info.facebook}>Zeb Wickman</a>
            </p>
            <h1 className="font-weight-light" style={{ paddingTop: "20px" }}>
              Fritz Baier Chiropractic Services
            </h1>
            <p>Phone: 712-254-2639</p>
            <h1 className="font-weight-light">Location</h1>
            <p>Address: {webinfo.location_info.address_full}</p>
            <p>{webinfo.location_info.description}</p>
            <p></p>
          </div>
        </div>
      </div>
      {/* <LocationRow></LocationRow> */}
    </div>
  );
}

export default Contact;
