const webinfo = {
  contact_info: {
    phone: "712-254-1322",
    facebook: "https://www.facebook.com/zeb.wickman.73",
    instagram: "doesn't have one",
    twitter: "none",
  },
  location_info: {
    address_full: "704 Walnut Suite #1, Atlantic, IA 50022",
    street: "704 Walnut Suite",
    building_number: "#1",
    town: "Atlantic",
    state: "IA",
    zip: "50022",
    hours: "Mon-Sat: 10am-7pm",
    description:
      "Located just off the corner or Walnut and 7th street in Atlantic Iowa",
  },
  about_info: {
    paragraph: "put table from flyer (massage modalities)",
  },
  carosel_info: {
    baseURL: "https://drive.google.com/uc?id=",
    photoIdList: [
      "/assets/ZWM-3.jpg",
      // "/assets/ZWM-10.jpeg",
      // "/assets/ZWM-21.jpeg",
      // "/assets/ZWM-25.jpeg",
      // "/assets/ZWM-29.jpeg",
      // "/assets/ZWM-47.jpeg",
      // "/assets/ZWM-53.jpeg",
      // "/assets/ZWM-56.jpeg",
      // "/assets/ZWM-67.jpeg",
      // "/assets/ZWM-75.jpeg",
    ],
  },
  bodyBasicsPhoto: "/assets/ZWM-3.jpg",
};

export default webinfo;
